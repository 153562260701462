import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { PrimaryBtn } from './Buttons/Primary';
import { SecondaryBtn } from './Buttons/Secondary';
import { h1 } from './Headings';

const Box = styled.div`
    margin: 0 auto;

    p {
        max-width: 545px;
        font-weight: ${({ homepage }) => (homepage ? 700 : 400)};
    }

    @media (min-width: 1280px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: min(80vw, 1070px);
    }

    @media (min-width: 1600px) {
        max-width: min(87vw, 1490px);

        p {
            max-width: ${({ homepage }) => (homepage ? '545px' : '750px')};
            margin-bottom: 32px;
        }
    }
`;

const Heading = styled.h3`
    font-size: 1.5rem;
    margin: 32px 0;

    > span {
        ${h1};
        display: block;
        margin-top: 3px;
    }

    @media (min-width: 1280px) {
        margin-top: 0;
        font-size: 1.75rem;

        > span {
            margin-top: 8px;
            font-size: 3.125rem;
        }
    }

    @media (min-width: 1600px) {
        font-size: 2.45rem;
    }
`;

const Logos = styled.div`
    max-width: 578px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Buttons = styled.div`
    margin-top: 16px;

    > #learnDrPage {
        margin-right: 32px;
        margin-bottom: 16px;
    }

    @media (min-width: 768px) {
        margin-top: 32px;

        > #learnDrPage {
            margin-bottom: 0;
        }
    }
`;

export default function AboutDr({ description, homepage }) {
    return (
        <Box homepage={homepage}>
            <StaticImage
                src="../images/about/dr.png"
                alt="doctor herbert land"
                css={theme => css`
                    filter: drop-shadow(0px 64px 64px rgba(33, 163, 185, 0.3));
                    width: 300px;
                    height: 300px;
                    border-radius: 50%;
                    display: block;
                    margin: 0 auto;
                    position: relative;
                    transition: filter 0.9s ease-in-out;

                    ::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        top: 0;
                        border-radius: 50%;
                        background: ${theme.gradient};
                        opacity: 0;
                        transition: opacity 0.9s ease-in-out;
                    }

                    img {
                        clip-path: circle(50% at 50% 50%);
                        transition: clip-path 0.9s ease-in-out, opacity 0.25s linear !important;
                        will-change: opacity, clip-path !important;
                    }

                    :hover {
                        filter: none;

                        ::before {
                            opacity: 1;
                        }

                        img {
                            clip-path: circle(48.3% at 50% 50%);
                        }
                    }

                    @media (min-width: 768px) {
                        width: 400px;
                        height: 400px;
                    }

                    @media (min-width: 1024px) {
                        width: 450px;
                        height: 450px;
                    }

                    @media (min-width: 1280px) {
                        margin: 0;
                    }

                    @media (min-width: 1600px) {
                        width: 600px;
                        height: 600px;
                    }
                `}
            />
            <div>
                <Heading as={homepage ? 'h2' : 'h3'}>
                    Meet our Board Certified Orthodontist
                    <span>Dr. Herbert Land</span>
                </Heading>
                <p>{description}</p>
                <Logos>
                    <StaticImage
                        src="../images/about/aao.png"
                        alt="american association of orthodontics"
                        css={css`
                            width: 94.72px;

                            @media (min-width: 768px) {
                                width: 185px;
                            }
                        `}
                    />
                    <StaticImage
                        src="../images/about/abo.png"
                        alt="american board of orthodontics"
                        css={css`
                            width: 75.52px;

                            @media (min-width: 768px) {
                                width: 118px;
                            }
                        `}
                    />
                    <StaticImage
                        src="../images/about/ada.png"
                        alt="american dental association"
                        css={css`
                            width: 94.08px;

                            @media (min-width: 768px) {
                                width: 147px;
                            }
                        `}
                    />
                </Logos>
                <Buttons>
                    {homepage ? (
                        <PrimaryBtn as={Link} to="/about-us/" id="learnDrPage">
                            Learn about dr land
                        </PrimaryBtn>
                    ) : (
                        ''
                    )}
                    <SecondaryBtn as="a" href="#contact-us">
                        Book an Appointment
                    </SecondaryBtn>
                </Buttons>
            </div>
        </Box>
    );
}
